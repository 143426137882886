<template>
  <ButtonStock class="textCenter w100 mb-10" title="Создать мероприятие" @click="addEvent" />
</template>

<script>
  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  export default {
    name: 'Widget18',
    components: {
      ButtonStock,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    methods: {
      addEvent() {
        this.$router.push({ name: 'EventCardAdd', query: { countryId: this.countryId } });
      },
    },
    data() {
      return {};
    },
    created() {},
  };
</script>

<style lang="scss" scoped></style>
